/* .start_main_parant {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #0444b3;
    background-image: linear-gradient(180deg, #48daf1 0%, #39da6c 100%);

} */

/* .wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 390px;
} */

/* .wrapper svg {
    width:900px;
    height: 100%;
    margin-top: 2%;
} */

/* .wrapper svg text {
    animation: stroke 3s infinite alternate;
    stroke-width: 2;
    stroke: red;
    font-size: 140px;
    font-weight: 700;
    color: red;

} */

/* .total_ques {
    font-size: 40px;
    font-weight: 600;
    padding-bottom: 10px;
}

.start_btn,
.quit_btn {
    border: none;
    background-color: black;
    color: white;
    padding: 10px 50px;
    font-size: 20px;
    font-weight: 700;
    border-radius: 30px;
}

.start_btn_div,
.quit_btn_div {
    text-align: center;
    padding-bottom: 20px;
} */

@keyframes stroke {
    0% {
        fill: rgba(255, 88, 27, 0);
        stroke: rgb(13, 17, 36);
        stroke-dashoffset: 25%;
        stroke-dasharray: 0 50%;
        stroke-width: 2;
    }

    70% {
        fill: rgba(27, 174, 255, 0);
        stroke: rgb(113, 141, 255);
    }

    80% {
        fill: rgba(27, 174, 255, 0);
        stroke: rgb(113, 141, 255);
        stroke-width: 3;
    }

    100% {
        fill: black;
        stroke: rgba(255, 113, 249, 0);
        stroke-dashoffset: -25%;
        stroke-dasharray: 50% 0;
        stroke-width: 0;
    }
}