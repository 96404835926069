.get_all_questionset_status_icons{
    width: 25px;
}

.delete_btn{
    font-size: 20px;
    background-color: red;
}
.all_question_data{
    text-align: center;
}
.all_question_data{
    font-size: 16px;
    text-align: center;
}
.all_question_content{
    height: 430px;
    overflow: auto;
    margin: 15px 0;
}
.all_question_content_btn {
    cursor: pointer;
    font-size: 17px;
    padding: 2px 7px;
    color: white;
    cursor: pointer;
    position: absolute;
    right: 7px;
    top: 7px;
    background-color: #ffbe00;
    font-weight: 600;
}