
.th_tech_sec,
.td_tech_sec {
  padding: 12px;
  text-align: center;
}

.th_tech_sec {
  background-color: #f2f2f2;
}
.btn_addtechnology_div {
    display: flex;
    justify-content: space-between;
    max-width: 95%;
    margin: 25PX auto;
}
.btn_addtechnology,.btn_back {
    border: none;
    background-color: #00c68f;
    color: whitesmoke;
    padding: 10px;
    font-weight: 500;
}
.status_icons{
    width: 25px;
}
.btn_back {
    background-color: #ffbe00;
}
.back_arrow {
    padding-left: 5px;
    top: 21px;
}
.showPopup{
    display: none;
}
/* ---------------- */
.popMainSec {
    width: 500px;
    margin: 30px auto;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    padding: 0 20px;
}

.popupBttn {
    display: flex;
    justify-content: space-between;
    margin-top: 2rem;
}

.closeBttn {
    font-size: 14px;
    padding: 5px 15px;
    border: none;
    background: #ff8966;
    color: white;
    font-weight: 700;
}

.addrole {
    background-color: #00ca95 !important;
}
.popMainSec.pt-2.pb-4 {
    position: absolute;
    top: 23%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #fff;
}
/* ---------------- */