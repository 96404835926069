.start_main_parant {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #A9C9FF;
  background-image: linear-gradient(180deg, #A9C9FF 0%, #FFBBEC 100%);

}

.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.wrapper svg {
  /* font-family: "Monoton", cursive; */
  width: 600px;
  height: 35vh;
  margin-top: 2%;
}

.wrapper svg text {
  animation: stroke 5s infinite alternate;
  stroke-width: 2;
  stroke: orange;
  font-size: 100px;
  color: red;

}

.total_ques {
  font-size: 40px;
  font-weight: 600;
  text-align: center;
  padding: 0 10px;
  padding-bottom: 10px; 
}

.start_btn,
.quit_btn {
  border: none;
  background-color: rgb(113, 141, 255);
  color: white;
  padding: 10px 50px;
  font-size: 20px;
  font-weight: 700;
  border-radius: 30px;
}

.start_btn_div,
.quit_btn_div {
  text-align: center;
  padding-bottom: 20px;
}

@keyframes stroke {
  0% {
    fill: rgba(255, 88, 27, 0);
    stroke: rgb(113, 141, 255);
    stroke-dashoffset: 25%;
    stroke-dasharray: 0 50%;
    stroke-width: 2;
  }

  70% {
    fill: rgba(27, 174, 255, 0);
    stroke: rgb(113, 141, 255);
  }

  80% {
    fill: rgba(27, 174, 255, 0);
    stroke: rgb(113, 141, 255);
    stroke-width: 3;
  }

  100% {
    fill: orange;
    stroke: rgba(255, 113, 249, 0);
    stroke-dashoffset: -25%;
    stroke-dasharray: 50% 0;
    stroke-width: 0;
  }
}

@media(max-width:768px) {
  .wrapper svg text {
    font-size: 50px;
  }

  .wrapper svg {
    /* font-family: "Monoton", cursive; */
    width: 300px;
  }

  .total_ques {
    font-size: 25px;
  }
}